import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faGithub,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import "./style.scss";

const SocialIcons = (props, context) => {
  const {
    theme: { colorPrimary },
  } = context;

  return (
    <div className="social-icons animate-icons">
      <a
        href="https://nirantak.dev"
        target="_blank"
        rel="noopener noreferrer"
        title="Portfolio"
        style={{ color: colorPrimary }}
      >
        <FontAwesomeIcon icon={faGlobe} />
      </a>
      <a
        href="https://github.com/nirantak"
        target="_blank"
        rel="noopener noreferrer"
        title="GitHub"
        style={{ color: colorPrimary }}
      >
        <FontAwesomeIcon icon={faGithub} />
      </a>
      <a
        href="https://www.linkedin.com/in/nirantak"
        target="_blank"
        rel="noopener noreferrer"
        title="LinkedIn"
        style={{ color: colorPrimary }}
      >
        <FontAwesomeIcon icon={faLinkedin} />
      </a>
      <a
        href="https://twitter.com/nirantakr"
        target="_blank"
        rel="noopener noreferrer"
        title="Twitter"
        style={{ color: colorPrimary }}
      >
        <FontAwesomeIcon icon={faTwitter} />
      </a>
      <a
        href="mailto:hey@nirantak.com"
        target="_blank"
        rel="noopener noreferrer"
        title="Email"
        style={{ color: colorPrimary }}
      >
        <FontAwesomeIcon icon={faEnvelope} />
      </a>
    </div>
  );
};

SocialIcons.contextTypes = {
  theme: PropTypes.any,
};

export default SocialIcons;
