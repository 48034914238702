import React from "react";
import PropTypes from "prop-types";
import SocialIcons from "components/SocialIcons";
import Nav from "components/Nav";

import "./style.scss";

const LandingPage = (props, context) => {
  const {
    theme: { bgPrimary, colorPrimary },
  } = context;

  return (
    <div style={{ backgroundColor: bgPrimary }} className="landing-page">
      <Nav />
      <main style={{ color: colorPrimary }}>
        <div className="intro-wrapper">
          <div className="intro-name">Nirantak Raghav</div>
          <div className="tagline">Software Engineer, DevOps and SRE</div>
          <SocialIcons />
        </div>
      </main>
    </div>
  );
};

LandingPage.contextTypes = {
  theme: PropTypes.any,
};

export default LandingPage;
