import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";

import "./style.scss";

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSticky: false,
    };
  }

  render() {
    const {
      theme: { colorPrimary, bgPrimary, navAlpha },
      switchTheme,
    } = this.context;

    const stickyClass = this.state.isSticky ? "sticky" : "";
    const stickyStyles = this.state.isSticky
      ? { backgroundColor: navAlpha, color: colorPrimary }
      : { backgroundColor: bgPrimary, color: colorPrimary };
    return (
      <nav
        className={stickyClass}
        ref={(elem) => {
          this.nav = elem;
        }}
        style={stickyStyles}
      >
        <div className="magic-wand" onClick={(e) => switchTheme()}>
          {/* beat bounce fade shake */}
          <FontAwesomeIcon icon={faWandMagicSparkles} size="lg" shake />
          <div className="magic-text">Color Me</div>
        </div>
      </nav>
    );
  }
}

Nav.contextTypes = {
  theme: PropTypes.any,
  switchTheme: PropTypes.func,
};

export default Nav;
